<script>
import Layout from "../../layouts/main";
import SessionLayout from '../../layouts/sessionLayout';
import ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import moment from 'moment';
import errorHandler from '@/helpers/errorHandler'
import ExportExcel from '../../../components/exportExcel.vue';

/**
 * Starter component
 */
export default {
  page:{
    title: 'Sessions students'
  },
  head() {
    return {
      title: `Sessions-Students`,
    };
  },
  components:{
    Layout,
    SessionLayout,
    ConfirmActionDialog,
    ExportExcel
  }, 
  async created(){
    await this.$store.dispatch('session/getSessionStudents',this.$route.params.session).then( ()=>{}).catch( err => {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    })
    await this.$store.dispatch('session/getSessionInfo',this.$route.params.session).then(()=>{
        this.session = this.$store.getters['session/getSession'];
    }).catch( err => {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    })
  },
  data() {
    return {
    session: null,
    perPage: 20,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "timestamp",
    sortDesc: false,
    filterOn: ["student","stud_id"],
    fields: [
      {
        key: "timestamp",
        label: "Attended time",
        sortable: true,
        selected: true
      },
      {
        key: "stud_id",
        label: "ID",
        sortable: true,
        selected: true
      },
      {
        key: "student.user.name",
        label: "Student name",
        sortable: true,
        selected: true
      },
      {
        key: "student.user.phone_number",
        label: "Phone",
        sortable: true,
        selected: true
      },
    //   {
    //     key: "date_time",
    //     label: "Date",
    //     sortable: true,
    //     selected: true
    //   },
      {
        key: "action",
        label: "",
        sortable: false,
        selected: true,
        disabled: true
      }
    ]
    };
  },
  filters:{
    formatDate(value){
      if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
      }
    }
  },
  methods: {
    RemoveRecord:function(){
     this.$store.dispatch("session/deleteStudent",this.recordId).then((token)=>{
      errorHandler.methods.successful('Item deleted successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
     showDeleteModal(id) {
            this.$bvModal.show('deleteRecord')
             this.recordId = id
    },
    viewProfile(record,index){
      this.$router.push('/students/' + record.student.stud_id + '/details')
    },
    loadImage(img){
      return `${process.env.VUE_APP_BASE_URL}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'profile_images'}/${img}`
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    students() {
      return this.$store.getters['session/getStudents'];
    },
    rows() {
      return this.students.length;
    }
  },
};
</script>

<template>
  <Layout>
    <SessionLayout :session="this.session">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                <div class="card-body">
                    <div class="row mb-2">
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                            Display&nbsp;
                            <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            ></b-form-select
                            >&nbsp;Records
                        </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                        >
                        <export-excel :jsonData="students" v-if="$store.getters['user/role'] == 0"></export-excel>
                        <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ml-2"
                            ></b-form-input>
                        </label>
                        <b-dropdown
                            id="dropdown-offset"
                            offset="250"
                            variant="link"
                            class="btn-icon-only"
                        >
                            <template #button-content>
                            <i class="fas fa-th-large iconGray"></i>
                            </template>
                            <b-dropdown-form>
                            <b-form-checkbox
                                v-for="(field, index) in fields"
                                :key="index"
                                class="mb-3"
                                v-model="field.selected"
                                :disabled="field.disabled"
                                >{{ field.label }}</b-form-checkbox
                            >
                            </b-dropdown-form>
                        </b-dropdown>
                        </div>
                    </div>
                    <!-- End search -->
                    </div>
                    <b-table
                    table-class="table table-centered w-100 table-hover"
                    thead-tr-class="bg-light"
                    tbody-tr-class="clickableRow"
                    :items="students"
                    :fields="tableFields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @row-clicked="viewProfile"
                    >
                    <template v-slot:cell(educational_year)="data">
                        {{ data.item.educational_year.number + ' - ' + data.item.educational_year.description }}
                    </template>
                    <template v-slot:cell(timestamp)="data">
                        {{ data.item.timestamp | formatDate }}
                    </template>
                    <template v-slot:cell(student.user.name)="data">
                        <img
                          v-if="data.item.student && data.item.student.user && data.item.student.user.image != null"
                          :src="loadImage(data.item.student.user.image)"
                          alt="contact-img"
                          title="contact-img"
                          class="avatar-sm rounded-circle img-thumbnail"
                        />
                        {{ data.item.student.user.name }}
                    </template>
                <template v-slot:cell(action)="data">
                        <b-dropdown
                        class="btn-icon-onl"
                        right
                        toggle-class="arrow-none btn-light btn-sm"
                        >
                        <template v-slot:button-content>
                            <i class="mdi mdi-dots-horizontal iconGray"></i>
                        </template>

                        <b-dropdown-item
                            :to="'/sessions/' +$route.params.session + '/' + data.item.id + '/edit'"
                        >
                            <i
                            class="mdi mdi-pencil mr-2 text-muted font-18 vertical-middle iconGray"
                            ></i
                            >Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                        v-on:click="showDeleteModal(data.item.id)"
                        ><i
                            class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                        ></i
                        >Delete
                        </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    </b-table>
                    <div class="row">
                    <div class="col">
                        <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                        >
                        <ul class="pagination pagination-rounded">
                            <!-- pagination -->
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="(filter == '' )?rows:totalRows"
                            :per-page="perPage"
                            ></b-pagination>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <b-modal id="deleteRecord" size="md" title="Warning Action">
                <ConfirmActionDialog/>
                <template #modal-footer>
                <b-button variant="outline-success" @click="RemoveRecord()">Delete</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
                </template>
            </b-modal>
    </SessionLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
