<template>
  <div class="col-lg-3 center">
    <div class="card ">
      <div class="card-body text-center">
        <h3 class="mt-3 mb-0">{{ session.date_time | formatDate}}</h3>
        <div class="text-left mt-3">
          <div class="table-responsive">
            <table class="table table-borderless table-sm">
              <tbody>
              <tr v-if="session.center">
                <th scope="row">Center :</th>
                <td class="text-muted">{{session.center.name}}</td>
              </tr>
              <tr v-if="session.educational_year">
                <th scope="row">Year :</th>
                <td class="text-muted" >
                  {{ session.educational_year.number + ' - ' + session.educational_year.description}}
                </td>
              </tr>
              <tr v-if="session.chapter">
                <th scope="row">Lecture :</th>
                <td class="text-muted" >
                  {{ session.chapter.name }}
                </td>
              </tr>
              <tr v-if="number">
                <th scope="row">Total Attended :</th>
                <td class="text-muted" >
                  {{ number }}
                </td>
              </tr>
              <tr v-if="session.assistant_createsession">
                <th scope="row">Creator :</th>
                <td class="text-muted" >
                  {{ session.assistant_createsession.name }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: 'SessionSidebar',
    props: ['session'],
    data(){
      return {
        number:0
      }
    },
    filters:{
      formatDate(value){
        if(value){
          return moment(String(value)).format('hh:mm A DD/MM/YYYY')
        }
      }
    },
    async created(){
      await this.$store.dispatch('statistics/getSessionStat',this.$route.params.session).then(()=>{
        this.number = this.$store.getters['statistics/sessionCount'];
      });
    }
  }
</script>

<style scoped>

</style>
